<template>
    <section class="section section--input">
        <div class="container">
            <div class="form">
                <div class="form__section">
                    <fieldset class="form__item">
                        <label for="productName">試算銘柄</label>
                        <div class="form__item__input">
                            <input type="text" name="productName" id="productName" v-model="selectedProduct.productName"
                                placeholder="銘柄名を入力してください" :class="{ emphasize: selectedProduct.productName === '' }">
                        </div>
                    </fieldset>

                    <fieldset class="form__item">
                        <label for="productType">金融商品分類</label>
                        <div class="form__item__input">
                            <div class="form__item__select">
                                <select name="productType" id="productType" v-model="selectedProduct.productType" :class="{ emphasize: selectedProduct.productType == 0 }">
                                    <option value="0">選択してください</option>
                                    <option value="1">ETF</option>
                                    <option value="2">日本株（キャピタルゲイン）</option>
                                    <option value="3">日本株（インカムゲイン）</option>
                                    <option value="4">米国株（キャピタルゲイン）</option>
                                    <option value="5">米国株（インカムゲイン）</option>
                                    <option value="6">定期預金</option>
                                    <option value="7">その他</option>
                                </select>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset class="form__item">
                        <label for="initialInvestment">初期投資額</label>
                        <div class="form__item__input">
                            <input type="tel" name="initialInvestment" id="initialInvestment" placeholder="100"
                                v-model="selectedProduct.initialInvestment" :class="{ emphasize: selectedProduct.initialInvestment === null }">
                            <span class="unit">万円</span>
                        </div>
                    </fieldset>

                    <fieldset class="form__item">
                        <label for="annualRate">年利</label>
                        <div class="form__item__range">
                            <button class="range-button decrease" @click="decrease('annualRate', 0.1)"></button>
                            <div>
                                <input class="form__item__range__slider" id="annualRate" name="annualRate" type="range"
                                    value="0" min="0" max="20" step="0.1" v-model="selectedProduct.annualRate"
                                    @input="updateValue('annualRate', selectedProduct.annualRate)">
                                <div class="form__item__range__container">
                                    <span class="form__item__range__value">{{ selectedProduct.annualRate }}</span>
                                    <span class="form__item__range__value form__item__range__value--unit">%</span>
                                </div>
                            </div>
                            <button class="range-button increase" @click="increase('annualRate', 0.1)"></button>
                        </div>
                    </fieldset>

                    <fieldset class="form__item">
                        <label for="holdingYears">投資期間</label>
                        <div class="form__item__range">
                            <button class="range-button decrease" @click="decrease('holdingYears', 1)"></button>
                            <div>
                                <div>
                                    <input class="form__item__range__slider" id="holdingYears" name="holdingYears"
                                        type="range" value="1" min="1" max="50" step="1"
                                        v-model="selectedProduct.holdingYears"
                                        @input="updateValue('holdingYears', selectedProduct.holdingYears)">
                                    <div class="form__item__range__container">
                                        <span class="form__item__range__value">{{ selectedProduct.holdingYears }}</span>
                                        <span class="form__item__range__value form__item__range__value--unit">年</span>
                                    </div>
                                </div>
                            </div>
                            <button class="range-button increase" @click="increase('holdingYears', 1)"></button>
                        </div>
                    </fieldset>
                </div>

                <div class="form__section">
                    <fieldset class="form__item">
                        <label for="taxType">税金<span class="notice-message">（グラフには反映されません）</span></label>
                        <div class="form__item__input">
                            <div class="form__item__select">
                                <select name="taxType" id="taxType" v-model="selectedProduct.taxType">
                                    <option value="0">新NISA(0%)</option>
                                    <option value="1">20.315%</option>
                                </select>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset class="form__item" v-if="isIncomeGain">
                        <label for="addType">追加方式</label>
                        <div class="form__item__input">
                            <div class="form__item__select">
                                <select name="addType" id="addType" v-model="selectedProduct.addType">
                                    <option value="0">設定しない</option>
                                    <option value="1">再投資</option>
                                </select>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
    data() {
        return {
            settings: {
                fill: '#37A2FF',
                background: '#fff'
            },
            isIncomeGain: false,
            rangeValue: 0,
            step: 0.1,
            max: 20
        }
    },
    computed: {
        ...mapState(['currentProductIndex', 'products']),
        selectedProduct() {
            return this.products[this.currentProductIndex] ?? {};
        },
        valueContainerLeft() {
            return (this.sliders[0].value / 20) * 100 + '%';
        }
    },
    methods: {
        ...mapMutations(['updateProduct', 'calcProfitB']),
        ...mapGetters(['getTotalProfit', 'calcProfit']),
        decrease: function (field, step) {
            let value = parseFloat(this.selectedProduct[field]);
            if (value - step >= 0) {
                this.selectedProduct[field] = (value - step).toFixed(field === 'annualRate' ? 1 : 0);
            }
        },
        increase: function (field, step) {
            let value = parseFloat(this.selectedProduct[field]);
            if (value + step <= (field === 'annualRate' ? 20 : 50)) {
                this.selectedProduct[field] = (value + step).toFixed(field === 'annualRate' ? 1 : 0);
            }
        },
        handleFormChange() {
            if (this.products.length > 0) {
                this.updateProduct({ index: this.currentProductIndex, updatedProduct: this.selectedProduct });
                this.isIncomeGain = this.selectedProduct.productType == 3 || this.selectedProduct.productType == 5;
                // this.calcProfit(this.selectedProduct)
                this.applyFill('annualRate', this.selectedProduct.annualRate);
                this.applyFill('holdingYears', this.selectedProduct.holdingYears);
                this.getTotalProfit()
            }
        },
        updateValue(property, value) {
            this.$nextTick(() => {
                this.applyFill(property, value);
            });
        },
        applyFill(property, value) {
            const slider = document.getElementById(property);
            const percentage = 100 * (value - slider.min) / (slider.max - slider.min);
            const bg = `linear-gradient(90deg, ${this.settings.fill} ${percentage}%, ${this.settings.background} ${percentage + 0.1}%)`;
            slider.style.background = bg;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.applyFill('annualRate', this.selectedProduct.annualRate);
            this.applyFill('holdingYears', this.selectedProduct.holdingYears);
            this.handleFormChange();
        });
    },
    watch: {
        selectedProduct: {
            deep: true,
            handler() {
                this.handleFormChange();
            },
        },
        currentProductIndex: {
            deep: true,
            handler() {
                this.applyFill('annualRate', this.selectedProduct.annualRate);
                this.applyFill('holdingYears', this.selectedProduct.holdingYears);
            },
        }
    },
};
</script>

<style scoped>
.notice-message {
    font-size: 14px;
    margin-left: 8px
}

.form__item__range {
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;
}

.form__item__range div {
    flex: 1;
}

.range-button {
    width: 32px;
    height: 32px;
    background-color: transparent;
    border: none;
    position: relative;
    border-radius: 4px;
    background: rgba(24, 147, 255, 0.1);
    cursor: pointer;
}

.range-button:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 12px solid var(--blue);
    transform: translate(-50%, -50%);
}

.range-button.decrease {
    transform: rotate(-90deg);
}

.range-button.increase {
    transform: rotate(90deg);
}
</style>

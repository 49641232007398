import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import './assets/css/reset.css'
import './assets/css/style.css'

axios.defaults.baseURL = 'https://api.mgmg.online/';
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

const app = createApp(App);

app.config.globalProperties.$axios = axios;

app.use(store)
   .use(router)
   .mount('#app');

<template>
    <div id="app">
        <header class="header">
            <div class="container">
                <div class="header__logo">
                    <img src="img/logo-n.png" alt="投資の学校プレミアム">
                </div>

                <button class="hamburger" :class="navOpen ? 'active' : ''" @click="toggleNav">
                    <span class="hamburger__bar"></span>
                    <span class="hamburger__bar"></span>
                    <span class="hamburger__bar"></span>
                </button>
            </div>
        </header>

        <nav class="nav" :class="navOpen ? 'active' : ''">
            <div class="container">
                <ul class="nav__list">
                    <li class="nav__list__item">
                        <a href="#" v-on:click="updateIsFirst(1)">積立シュミレーションについて</a>
                    </li>

                    <li class="nav__list__item">
                        <a href="#">月収を狙う投資手法</a>
                    </li>

                    <li class="nav__list__item">
                        <a href="https://toushi-gp.jp" target="_blank">投資の学校とは</a>
                    </li>
                </ul>

                <div class="sns">
                    <ul class="sns__list">
                        <li class="sns__list__item sns__list__item--instagram">
                            <a href="#">Instagram</a>
                        </li>

                        <li class="sns__list__item sns__list__item--youtube">
                            <a href="https://www.youtube.com/channel/UCLdd2rML_6GhzkF_NByATIg"
                                target="_blank">Youtube</a>
                        </li>
                    </ul>
                </div>

                <div class="logout" v-if="auth">
                    <button @click="confirmLogout">ログアウト</button>
                </div>
                <div class="logout" v-else>
                    <button @click="confirmLogin">登録済みの方はこちら</button>
                </div>

            </div>
        </nav>

        <div v-if="showRegister" class="modal">
            <div class="modal__contents">
                <div class="register">
                    <img src="img/mail.svg" alt="">
                    <template v-if="!verify">
                        <p>3つ以上の試算銘柄を追加するには<br>メールアドレスの確認が必要です</p>
                        <input type="email" placeholder="mail@example.com" v-model="form.email">
                        <p v-if="err.register" class="error">{{ err.register }}</p>
                        <button class="btn btn--white btn--rounded" @click="register">確認メールを送る</button>
                        <button class="btn btn--inline" @click="resend">認証コードを再送する</button>
                        <button class="btn btn--inline" @click="showRegister = !showRegister">後で登録する</button>
                    </template>
                    <template v-else>
                        <p>認証コードを送信しました</p>
                        <p>メール記載の認証コードをご入力ください</p>
                        <p>※メールが届かない場合は、迷惑メールフォルダの確認分のドメインフィルタの解除の記載をお願いします</p>
                        <input type="tel" placeholder="1234" v-model="form.pin">
                        <p v-if="err.pincode" class="error">{{ err.pincode }}</p>
                        <button class="btn btn--white btn--rounded" @click="activation">認証する</button>
                        <button class="btn btn--inline" @click="verify = !verify">戻る</button>
                    </template>
                </div>
            </div>
        </div>

        <div v-if="newface" class="modal">
            <div class="modal__contents">
                <b>メールアドレスの登録完了</b>

                <img src="img/cracker.svg" alt="メールアドレスの登録完了">

                <p>試算銘柄を最大<span>10</span>個まで<br>シュミレーションができます</p>

                <button @click="newface = !newface" class="btn btn--white btn--rounded">試算・見積もりをはじめる</button>
            </div>
        </div>

        <div v-if="isFirst == 1" class="modal">
            <div class="modal__contents">
                <b>積立シュミレーター</b>

                <div class="text-group">
                    <p>このツールは、複数の投資商品（銘柄）で長期投資をした場合の『リアルな投資行動』を想定した収支シミュレーションツールです。</p>
                    <p>よくあるシミュレーションツールは、1つの銘柄を年利何%換算で何年間保有すれば、将来的に合計いくらになるか、など算出されるケースがほとんどですが、実際に投資によって将来の資産構築を目指す方は複数の銘柄に、さまざまなライフステージの必要資金を想定し投資金額を変更したり、一部解約したり、まとまった資金を投資したりとさまざまな投資行動を行います。
                    </p>
                    <p>
                        本ツールはこのようにさまざまなライフステージにおける複数銘柄の横断的な運用を想定したシミュレーションツールになります。<br>
                        無料で利用することができますので、お役立て頂けましたら幸いです。
                    </p>
                </div>

                <button @click="updateIsFirst(0)" class="btn btn--white btn--rounded">試算・見積もりをはじめる</button>
            </div>
        </div>

        <div class="title">
            <div class="container">
                <h1>積立シュミレーション</h1>
            </div>
        </div>

        <div class="tab" id="productTabs" ref="productTabs">
            <div class="container">
                <div class="tab__wrapper">
                    <div class="tab__products">
                        <div v-for="(product, key) in products" :key="key" class="tab__products__item">
                            <button class="tab__products__item__button" :data-product="key"
                                @click="changeProduct(key), showRegister = false"
                                :class="{ 'tab__products__item__button--current': currentProductIndex === key }">
                                <template v-if="product.productName.length > 0">{{ product.productName }}</template>
                                <template v-else>試算銘柄{{ key + 1 }}</template>
                            </button>
                        </div>
                    </div>

                    <button id="addProduct" class="tab__item tab__item--new btn btn--blue btn--rounded btn--add"
                        @click="addNewProduct()">追加する</button>
                </div>
            </div>
        </div>

        <article>
            <ProductInput />

            <section v-if="showGraph" class="section section--graph">
                <div class="container">
                    <div class="estimate">
                        <div class="estimate__tooltip">
                            <div class="estimate__tooltip__year">
                                <span>{{ this.year }}年目</span>
                            </div>

                            <div class="estimate__tooltip__total">
                                <span>{{ Math.floor(this.total).toLocaleString() }}円</span>
                                <span v-if="selectedProduct.addType == 1" class="estimate__tooltop__unit">(積立元本 +
                                    増えた額)</span>
                                <span v-else class="estimate__tooltop__unit">(積立元本 + 累積増えた額)</span>
                            </div>

                            <div class="estimate__tooltip__breakdown estimate__tooltip__breakdown--profit">
                                <span class="estimate__tooltop__label">累積増えた額</span>
                                <span class="estimate__tooltop__unit">{{ Math.floor(this.profitB).toLocaleString()
                                    }}円</span>
                            </div>


                            <div class="estimate__tooltip__breakdown estimate__tooltip__breakdown--profit">
                                <span class="estimate__tooltop__label">増えた額</span>
                                <span class="estimate__tooltop__unit">{{ Math.floor(this.profit).toLocaleString()
                                    }}円</span>
                            </div>

                            <div class="estimate__tooltip__breakdown">
                                <span class="estimate__tooltop__label">積立元本</span>
                                <span class="estimate__tooltop__unit">{{ Math.floor(this.principal).toLocaleString()
                                    }}円</span>
                            </div>
                        </div>

                        <div id="estimateGraph" class="estimate__graph">
                            <div style="height: 100%" @click="requestExtra(i)" v-for="(d, i) in currentProfit" :key="i"
                                class="estimate__graph__item"
                                :class="{ 'active': currentBarIndex === i || currentBarIndex === null, 'estimate__graph__item--extra': d.hasExtraCost }"
                                v-on:mouseover="updateTooltip(i)">
                                <p v-if="setLabel(i)" class="estimate__graph__item__label">{{ i + 1 }}年目</p>
                                <div class="estimate__graph__item__profit" :style="{ height: d.profitHeight + '%' }">
                                </div>
                                <div class="estimate__graph__item__principal"
                                    :style="{ height: d.principalHeight + '%' }">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section v-if="showGraph" class="section section--extra">
                <div class="container">
                    <div class="extra">
                        <div v-if="!isShowExtra" class="extra__inactive">
                            <p>任意の年度をタップして<br>追加投資することができます</p>
                        </div>

                        <div v-else class="extra__active">
                            <div class="form">
                                <fieldset class="form__item">
                                    <label for="extraCost">追加投資（{{ currentBarIndex + 1 }}年目）</label>
                                    <div class="form__item__input">
                                        <input type="tel" name="extraCost" id="extraCost" placeholder="100"
                                            v-model="selectedProduct.extraCostHistory[currentBarIndex + 1]">
                                        <span class="unit">万円</span>
                                    </div>
                                </fieldset>
                            </div>

                            <ul id="extraCostHistory" class="extra__history">
                                <template v-for="(value, key) in selectedProduct.extraCostHistory" :key="key">
                                    <li v-if="key != currentBarIndex + 1 && value > 0" class="extra__history__item">
                                        <button type="button" class="extra__history__item__value"
                                            @click="updateBarIndex(key - 1)">
                                            <span class="extra__history__item__year">{{ key }}年目</span>
                                            <span class="extra__history__item__amount">{{ value }}<span
                                                    class="unit">万円</span></span>
                                        </button>
                                        <button type="button" class="extra__history__item__delete"
                                            @click="deleteExtraCostHistory(key - 1)">削除</button>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </article>

        <section class="section section--total">
            <div class="container">
                <div class="total">
                    <ul class="total__products">
                        <li v-for="(product, key) in products" :key="key">
                            <a href="#" @click.prevent="changeProduct(key); scrollTo('productTabs')">{{
                    product.productName
                }}</a>
                        </li>
                    </ul>

                    <p class="total__text">を合算した想定資産額</p>

                    <div class="total__estimate">
                        <p class="total__estimate__price">{{ Math.floor(totalProfit.total).toLocaleString() }}<span
                                class="unit">円</span></p>
                        <div class="total__estimate__breakdown">
                            <p>積立元本 {{ Math.floor(totalProfit.principal).toLocaleString() }}円</p>
                            <p>増えた額 {{ Math.floor(totalProfit.profit).toLocaleString() }}円</p>
                            <p>税金 -{{ Math.floor(totalProfit.tax).toLocaleString() }}円</p>
                        </div>
                    </div>

                    <p class="notice">
                        ※計算された数値は、あくまでもシミュレーションであり、将来の市場環境の変動や運用成果等を示唆および保証するものではありません。また、手数料、費用等を考慮しておりません。
                    </p>
                </div>
            </div>
        </section>

        <section class="section section--suggest">
            <div class="container">
                <div class="btn-wrapper">
                    <button type="button" class="btn btn--rounded btn--blue btn--add"
                        @click="addNewProduct(); scrollTo('productTabs')">さらに試算銘柄を追加する</button>
                    <p v-if="!auth" class="notice">※3つ以上の商品を登録するにはメールアドレスの確認が必要です</p>
                    <template v-if="products[currentProductIndex].productName.length > 0">
                        <button v-if="products.length" type="button" class="btn btn--rounded btn--inline btn--cancel"
                            @click="deleteCurrentProduct(); scrollTo('productTabs')">
                            試算銘柄「{{ products[currentProductIndex].productName }}」を削除する</button>
                    </template>
                    <template v-else>
                        <button v-if="products.length" type="button" class="btn btn--rounded btn--inline btn--cancel"
                            @click="deleteCurrentProduct(); scrollTo('productTabs')">試算銘柄{{currentProductIndex+1}}を削除する</button>
                    </template>
                </div>
            </div>
        </section>

        <div class="promotion">
            <div class="container">
                <a href="https://toushi-gp.jp/" target="_blank">
                    <picture>
                        <source media="(max-width: 820px)"
                            srcset="https://placehold.jp/18/ffffff/949494/600x350.png?text=%E3%81%93%E3%81%93%E3%81%AB%E5%BA%83%E5%91%8A%E3%82%92%E3%81%84%E3%82%8C%E3%82%89%E3%82%8C%E3%81%BE%E3%81%99">
                        <source
                            srcset="https://placehold.jp/18/ffffff/949494/800x300.png?text=%E3%81%93%E3%81%93%E3%81%AB%E5%BA%83%E5%91%8A%E3%82%92%E3%81%84%E3%82%8C%E3%82%89%E3%82%8C%E3%81%BE%E3%81%99">
                        <img
                            src="https://placehold.jp/18/ffffff/949494/800x300.png?text=%E3%81%93%E3%81%93%E3%81%AB%E5%BA%83%E5%91%8A%E3%82%92%E3%81%84%E3%82%8C%E3%82%89%E3%82%8C%E3%81%BE%E3%81%99">
                    </picture>
                </a>
            </div>
        </div>

        <footer>
            <div class="container">
                <address>&copy; 投資の学校プレミアム</address>
            </div>
        </footer>
    </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import ProductInput from '../components/ProductInput.vue'

export default {
    data() {
        return {
            isShowExtra: false,
            newface: false,
            isLoggedIn: false,
            navOpen: false,
            showRegister: false,
            activeProductIndex: 0,
            year: 0,
            total: 0,
            principal: 0,
            profit: 0,
            profitB: 0,
            activeIndex: null,
            showGraph: false,
            form: {
                email: '',
                pin: '',
            },
            err: {
                register: '',
                pincode: '',
                system: '',
                login: '',
            },
            already: false,
            verify: false
        }
    },
    computed: {
        ...mapState([
            'products',
            'currentProductIndex',
            'currentBarIndex',
            'currentProfit',
            'totalProfit',
            'auth',
            'isFirst'
        ]),
        selectedProduct() {
            return this.products[this.currentProductIndex] ?? {};
        },
    },
    methods: {
        ...mapMutations([
            'addProduct',
            'deleteProduct',
            'updateBarIndex',
            'setLogout',
            'setLogin',
            'updateProductIndex',
            'deleteExtraCost',
            'calcProfit',
            'setLogin',
            'updateIsFirst'
        ]),
        ...mapGetters([
            'getCurrentBarIndex'
        ]),
        requestExtra: function (index) {
            let ci = null
            if (this.currentBarIndex == index) {
                this.isShowExtra = false
            } else {
                ci = index
                this.isShowExtra = true
            }
            this.updateBarIndex(ci)
            this.updateTooltip(index)
            // }
            this.$emit('extra');
        },
        setLabel(index) {
            const maxIndex = this.currentProfit.length
            const currentIndex = index + 1

            // 最初
            if (currentIndex === 1) return true;
            // 最後
            if (currentIndex === maxIndex) return true;
            // 真ん中
            if (currentIndex === Math.floor(maxIndex / 2)) return true;

            return false
        },
        handleFormChange() {
            if (
                this.selectedProduct.productType != 0 &&
                this.selectedProduct.initialInvestment &&
                this.selectedProduct.annualRate &&
                this.selectedProduct.holdingYears
            ) {
                this.showGraph = true
                this.calcProfit(this.selectedProduct)
                this.updateTooltip(this.currentProfit.length - 1)
            }
        },
        updateTooltip(index) {

            let targetIndex = index
            if (this.getCurrentBarIndex() != null) {
                targetIndex = this.getCurrentBarIndex()
            }

            if (this.currentProfit[targetIndex]) {
                this.year = this.currentProfit[targetIndex].year
                this.total = this.currentProfit[targetIndex].total
                this.principal = this.currentProfit[targetIndex].principal
                this.profit = this.currentProfit[targetIndex].profit
                this.profitB = this.currentProfit[targetIndex].profitB
            }
        },
        confirmLogin() {
            this.$router.push('/login');
        },
        confirmLogout() {
            if (confirm('ログアウトすると試算情報が削除されますがよろしいですか？')) {
                this.$axios.post('api/logout')
                    .then(() => {
                        this.setLogout()
                        this.$router.push('/login');
                    })
                    .catch(() => {
                        alert("システムエラーが発生しました。ログイン画面に戻ります。")
                        this.$router.push('/login');
                    })
            }
        },
        toggleNav() {
            this.navOpen = !this.navOpen
        },
        toggleExtra() {
            if (this.currentBarIndex == null) {
                this.isShowExtra = false
                return
            }

            this.isShowExtra = true
        },
        deleteExtraCostHistory(index) {
            this.deleteExtraCost(index)
        },
        activation() {
            this.err.pincode = ""
            this.$axios.get('sanctum/csrf-cookie')
                .then(() => {
                    this.$axios.post('api/verify-pin', {
                        pin: this.form.pin,
                        email: this.form.email,
                    })
                        .then((res) => {
                            if (res.data.result) {
                                this.setLogin()
                                this.newface = true
                                this.showRegister = false
                            } else {
                                this.err.pincode = res.data.message
                            }
                        })
                        .catch((err) => {
                            this.err.system = err.response.data.message
                        })
                })
                .catch((res) => {
                    this.err.system = res.response.data.message
                })
        },
        register() {
            // メールアドレスの形式をチェック
            if (!this.isValidEmail(this.form.email)) {
                this.err.register = '有効なメールアドレスを入力してください。';
                return;
            }

            this.err.register = ''
            this.$axios.get('sanctum/csrf-cookie')
                .then(() => {
                    this.$axios.post('api/register', {
                        name: this.form.email,
                        email: this.form.email,
                        password: this.form.email
                    })
                        .then((res) => {
                            if (res.status == 200) {
                                this.verify = true
                            }
                        })
                        .catch(() => {
                            this.err.register = 'このメールアドレスは登録されているか\nすでに認証コードをお送りしております'
                        })
                })
        },
        resend() {
            this.err.pincode = ""
            this.$axios.get('sanctum/csrf-cookie')
                .then(() => {
                    this.$axios.post('api/resend-pin', {
                        email: this.form.email,
                    })
                        .then((res) => {
                            if (res.data.result) {
                                this.verify = true
                                this.err.pincode = '認証コードを再送信しました'
                            } else {
                                this.err.register = 'このメールアドレスはすでに認証されているか\n登録されておりません'
                            }
                        })
                        .catch((res) => {
                            this.err.register = res.response.data.message
                        })
                })
        },
        addNewProduct() {

            // 試算銘柄 最大数
            let limit = this.auth ? 10 : 2

            // 試算銘柄 最大数チェック
            if (this.products.length >= limit) {
                // 認証されていない場合は常に登録案内を表示
                if (!this.auth) {
                    this.showRegister = true;
                }
                return;
            }

            // 新しい商品情報
            const newProduct = {
                productName: '',
                productType: 0,
                initialInvestment: null,
                annualRate: 0,
                holdingYears: 1,
                taxType: 0,
                addType: 1,
                extraCostHistory: {},
            };

            this.showGraph = false
            this.addProduct(newProduct);
            this.updateProductIndex(this.products.length - 1)
        },
        changeProduct(index) {
            this.updateProductIndex(index)

            if (
                this.selectedProduct.productType == 0 ||
                this.selectedProduct.initialInvestment == null ||
                this.selectedProduct.annualRate == 0
            ) {
                this.showGraph = false
            }
        },
        deleteCurrentProduct() {
            if (confirm(`削除してよろしいですか？`)) {
                if (this.products.length == 1) {
                    this.showGraph = false
                }
                this.deleteProduct(this.currentProductIndex);
            }

        },
        isValidEmail(email) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailPattern.test(email);
        },
        scrollTo(refName) {
            const el = this.$refs[refName]
            el.scrollIntoView({ behavior: 'smooth' })
        }
    },
    mounted() {
        this.$axios.get('sanctum/csrf-cookie')
            .then(() => {
                this.$axios.get('api/authenticated')
                    .then((res) => {
                        console.log(res.data);
                        if (res.data.authenticated) {
                            this.setLogin()
                        }
                    })
            })

        this.handleFormChange();

        if (this.$route.query.n) {
            this.newface = true;
        }
    },
    watch: {
        selectedProduct: {
            deep: true,
            handler() {
                this.handleFormChange();
            },
        },
    },
    components: {
        ProductInput,
    },
}
</script>

<style lang="scss" scoped>
#ad1 {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
}

.ad {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        height: 100%;
        border: 2px solid #ddd;
    }
}

.estimate__tooltip__total {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .estimate__tooltop__unit {
        font-size: 18px;
        font-weight: 400;
    }
}

.estimate__tooltip__breakdown {
    display: flex;
    justify-content: space-between;
    background: #37A2FF;
    color: #fff;
    margin-top: 8px;
    align-items: center;
    padding: 4px 8px;

    &--profit {
        background: #006BC9;
    }
}

.estimate__tooltop__label {
    font-size: 18px;
}

.estimate__tooltop__unit {
    font-size: 24px;
}

.logout {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;

    button {
        color: #fff;
        font-size: 18px;
        font-weight: 800;
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            background: rgba(193, 193, 193, 0.3);
        }
    }
}

.register {
    button.btn.btn--inline {
        color: var(--blue);
    }
}

.modal input {
    background: #ededed;
}
</style>
import { createStore } from 'vuex'

const localStorageProducts = JSON.parse(localStorage.getItem('products')) || [
  {
    productName: '',
    productType: 0,
    initialInvestment: null,
    annualRate: 0,
    holdingYears: 1,
    taxType: 0,
    addType: 1,
    extraCostHistory: {},
  }
];
localStorage.setItem('products', JSON.stringify(localStorageProducts));

const localStorageIsFirst = localStorage.getItem('isFirst') || 1;
localStorage.setItem('isFirst', localStorageIsFirst);

function getTaxAmount(product) {
  return product.profitB * (product.taxType == 1 ? 0.20315 : 0)
}

function getProfits(product) {
  const {
    productType,
    annualRate,
    holdingYears,
    addType,
    initialInvestment,
    extraCostHistory,
    taxType,
  } = product;

  let initialCost = initialInvestment * 10000; // 初期投資額
  let r = annualRate / 100; // 年利率

  let yearlyData = [];
  let profitB = 0

  for (let year = 1; year <= holdingYears; year++) {

    // その年の追加投資額
    let extraCost = extraCostHistory[year] * 10000 || 0;

    // その年の初めの元本
    let P = (yearlyData[year - 2] ? yearlyData[year - 2].principal : initialCost);

    // 再投資
    if (productType == 3 || productType == 5) {
      if (addType == 1 && yearlyData[year - 2]) {
        P += yearlyData[year - 2].profit;
      }
    }

    // 追加投資額
    P += extraCost

    // その年の終わりの合計金額
    let A = P * Math.pow((1 + r), 1);

    // その年の利益
    let profit = A - P;

    // 累積利益
    profitB += profit

    let total = Math.floor(P + profitB);
    if (productType == 3 || productType == 5) {
      if (addType == 1) {
        total = Math.floor(A)
      }
    }

    yearlyData.push({
      year: year,
      principal: Math.floor(P), // 元本
      profit: Math.floor(profit), // 利益
      total: total,
      hasExtraCost: extraCost > 0, // 追加投資フラグ
      profitB: profitB,
      taxType: taxType,
      addType: addType,
      initialInvestment: initialInvestment,
    });
  }

  let heightRatio = 60;
  let maxTotal = yearlyData[yearlyData.length - 1].total;

  yearlyData = yearlyData.map(d => ({
    ...d,
    principalHeight: d.principal / maxTotal * heightRatio,
    profitHeight: d.profitB / maxTotal * heightRatio
  }));

  return yearlyData
}

export default createStore({
  state: {
    products: localStorageProducts,
    currentProductIndex: 0,
    currentBarIndex: null,
    currentProfit: [],
    totalProfit: { total: 0, profit: 0, principal: 0, tax: 0 },
    auth: false,
    isFirst: localStorageIsFirst
  },
  mutations: {
    addProduct(state, product) {
      if (state.products.length < 10) {
        state.products.push(product);
        state.currentProfit = []
        localStorage.setItem('products', JSON.stringify(state.products));
      }
    },
    updateProduct(state, { index, updatedProduct }) {
      state.products[index] = updatedProduct;
      localStorage.setItem('products', JSON.stringify(state.products));
      // this.dispatch('calcTotalProfit')
    },
    updateProductIndex(state, index) {
      state.currentProductIndex = index;
    },
    updateIsFirst(state, isFirst) {
      state.isFirst = isFirst
      localStorage.setItem('isFirst', state.isFirst);
    },
    updateBarIndex(state, index) {
      state.currentBarIndex = index;
    },
    deleteExtraCost(state, index) {

      let extraCostObj = state.products[state.currentProductIndex].extraCostHistory

      const filteredObj = {};
      for (const key in extraCostObj) {
        if (parseInt(key) !== index + 1) {
          filteredObj[key] = extraCostObj[key];
        }
      }

      state.products[state.currentProductIndex].extraCostHistory = filteredObj
    },
    calcProfit(state, product) {
      state.currentProfit = getProfits(product)
    },
    deleteProduct(state, index) {
      state.products.splice(index, 1);
      state.currentProductIndex = 0
      if (state.products.length > 0) {
        localStorage.setItem('products', JSON.stringify(state.products));
      } else {
        state.currentProfit = []
        localStorage.clear()
        state.products.push({
          productName: '',
          productType: 0,
          initialInvestment: null,
          annualRate: 0,
          holdingYears: 1,
          taxType: 0,
          addType: 1,
          extraCostHistory: {},
        })
      }
    },
    setLogin(state) {
      state.auth = true
      localStorage.setItem('auth', 1);
    },
    setLogout(state) {
      state.auth = false
      state.products =
        [
          {
            productName: '',
            productType: 0,
            initialInvestment: null,
            annualRate: 0,
            holdingYears: 1,
            taxType: 0,
            addType: 1,
            extraCostHistory: {},
          }
        ]
      state.currentProductIndex = 0
      state.currentBarIndex = null
      state.currentProfit = []
      state.totalProfit = { total: 0, profit: 0, principal: 0, tax: 0 }
      localStorage.clear()
    },
  },
  actions: {
    addProductAsync({ commit }, product) {
      setTimeout(() => {
        commit('addProduct', product);
      }, 1000);
    },
  },
  getters: {
    getLoginStatus: state => state.auth,
    getProducts: state => state.products,
    getCurrentBarIndex: state => state.currentBarIndex,
    getTotalProfit(state) {
      state.totalProfit = { total: 0, profit: 0, principal: 0, tax: 0 }

      for (let i = 0; i < state.products.length; i++) {
        let productProfits = getProfits(state.products[i]);
        let productProfit = productProfits[productProfits.length - 1];

        // 利益
        state.totalProfit.profit += productProfit.profitB;

        // 税額
        let taxAmount = getTaxAmount(productProfit)
        state.totalProfit.tax += taxAmount

        // 元本
        if (productProfit.addType == 1) {
          let totalInvestment = productProfit.initialInvestment * 10000
          for (const extraCost in state.products[i].extraCostHistory) {
            if (state.products[i].extraCostHistory[extraCost] > 0) {
              totalInvestment += parseInt(state.products[i].extraCostHistory[extraCost]) * 10000
            }
          }
          state.totalProfit.principal += totalInvestment
          // 総額
          state.totalProfit.total += totalInvestment + (productProfit.profitB - taxAmount)
        } else {
          state.totalProfit.principal += productProfit.principal
          // 総額
          state.totalProfit.total += productProfit.principal + (productProfit.profitB - taxAmount)
        }
      }

    },
  },
})
<template>
    <article class="login">
        <div class="container">
            <div class="login__logo">
                <img src="img/logo.svg" alt="投資の学校 プレミアム">
            </div>

            <section v-if="!already && !verify">
                <router-link to="/" class="btn btn--rounded btn--blue btn--large">試算・見積もりをはじめる</router-link>
                <button class="btn btn--white btn--rounded" @click="already = !already">登録済みの方はこちら</button>
            </section>

            <section v-if="!already && !verify">
                <p>メールアドレスの登録をされない場合、商品を2つまで積立シュミレーションを行えます。</p>
                <p>3つ以上の商品で積立シュミレーションする場合はメールアドレスの登録をおすすめします。</p>

                <input type="email" placeholder="mail@example.com" v-model="form.email">
                <p v-if="err.register" class="error">{{ err.register }}</p>
                <button class="btn btn--white btn--rounded" @click="register">確認メールを送る</button>
                <button class="btn btn--inline" @click="resend">認証コードを再送する</button>
            </section>

            <section v-if="verify">
                <p>認証コードを送信しました</p>
                <p>メール記載の認証コードをご入力ください</p>
                <p>※メールが届かない場合は、迷惑メールフォルダの確認分のドメインフィルタの解除の記載をお願いします</p>

                <input type="tel" placeholder="1234" v-model="form.pin">
                <p v-if="err.pincode" class="error">{{ err.pincode }}</p>
                <button class="btn btn--white btn--rounded" @click="activation">認証する</button>
                <button class="btn btn--inline" @click="verify = !verify">あとで登録する</button>
            </section>

            <section v-if="already">
                <form @submit.prevent="login">
                    <label>ご登録のメールアドレス</label>
                    <input type="email" placeholder="mail@example.com" v-model="form.email">

                    <p v-if="err.login" class="error">{{ err.login }}</p>
                    <button type="submit" class="btn btn--white btn--rounded">試算・見積もりをはじめる</button>

                    <button class="btn btn--inline" @click="already = !already">戻る</button>
                </form>
            </section>
        </div>
    </article>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
export default {
    data() {
        return {
            form: {
                email: '',
                pin: '',
            },
            err: {
                register: '',
                pincode: '',
                system: '',
                login: '',
            },
            already: false,
            verify: false
        }
    },
    computed: {
        ...mapGetters(['getLoginStatus']),
    },
    methods: {
        ...mapMutations(['setLogin']),
        toggleLoginForm() {
            this.already = !this.already
            this.verify = !this.verify
        },
        login() {
            this.error = ''
            this.$axios.get('sanctum/csrf-cookie')
                .then(() => {
                    this.$axios.post('api/login', {
                        email: this.form.email,
                        password: this.form.email
                    })
                        .then((res) => {
                            if (res.status == 200) {
                                this.setLogin()
                                this.$router.push('/');
                            }
                        })
                        .catch(() => {
                            this.err.login = 'このメールアドレスは登録されていません'
                        })
                })
                .catch((res) => {
                    console.log(res);
                })
        },
        resend() {
            this.err.pincode = ""
            this.$axios.get('sanctum/csrf-cookie')
                .then(() => {
                    this.$axios.post('api/resend-pin', {
                        email: this.form.email,
                    })
                        .then((res) => {
                            if (res.data.result) {
                                this.verify = true
                                this.err.pincode = '認証コードを再送信しました'
                            } else {
                                this.err.register = 'このメールアドレスはすでに認証されているか\n登録されておりません'
                            }
                        })
                        .catch((res) => {
                            this.err.register = res.response.data.message
                        })
                })
        },
        register() {
            // メールアドレスの形式をチェック
            if (!this.isValidEmail(this.form.email)) {
                this.err.register = '有効なメールアドレスを入力してください';
                return;
            }

            this.err.register = ''
            this.$axios.get('sanctum/csrf-cookie')
                .then(() => {
                    this.$axios.post('api/register', {
                        name: this.form.email,
                        email: this.form.email,
                        password: this.form.email
                    })
                        .then((res) => {
                            if (res.status == 200) {
                                this.verify = true
                            }
                        })
                        .catch((res) => {
                            console.log(res.response);
                            this.err.register = 'このメールアドレスは登録されているか\nすでに認証コードをお送りしております'
                        })
                })
        },
        activation() {
            this.err.pincode = ""
            this.$axios.get('sanctum/csrf-cookie')
                .then(() => {
                    this.$axios.post('api/verify-pin', {
                        pin: this.form.pin,
                        email: this.form.email,
                    })
                        .then((res) => {
                            if (res.data.result) {
                                this.setLogin()
                                this.$router.push('/?n=1');
                            } else {
                                this.err.pincode = res.data.message
                            }
                        })
                        .catch((err) => {
                            this.err.system = err.response.data.message
                        })
                })
        },
        isValidEmail(email) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailPattern.test(email);
        }
    }
}
</script>